import ReactDOM from "react-dom/client";
import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import {
  doPost,
  check_role_restrictions,
  doPostImage,
} from "./../../utils/apiCalls";
import { validateEmail } from "../../utils/functions";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import LOGO from "../../assets/images/logo.svg";
import SideBar from "./../../base-components/SideBar";
import SettingsBar from "./../../base-components/SideBar/Settings";
import Header from "./../../base-components/Header";

function Login() {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();
  const [role, setRole] = useState(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [emailinvoice, setEmailInvoice] = useState("");
  const [mobile, setMobile] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [image, setImage] = useState("");
  const [imagedata, setImageData] = useState("");
  const [loading, setLoading] = useState(false);
  const [btnloading, setBtnLoading] = useState(false);

  const path = window.location.pathname;
  const lastSlashIndex = path.lastIndexOf("/");
  const slugFromUrl =
    lastSlashIndex !== -1 ? path.substring(lastSlashIndex + 1) : "";

  // useEffect(() => {
  //   if (getLoggedObject()?.ac_type == 9) {
  //     navigate("/");
  //     return;
  //   }
  // });

  const do_update_company = async () => {
    const payload = {
      current: name,
      newpass: email,
      cnewpass: emailinvoice,
      token: getLoggedObject()?.token,
    };
    let API =
      getLoggedObject()?.ac_type === 9
        ? "auth/update_password_vendor "
        : "auth/update_password";
    setBtnLoading(true);
    const { isError, data } = await doPost(payload, API);
    if (isError) {
      toast.error("Something went wrong with server.");
      setBtnLoading(false);
    } else {
      if (data.action == "success") {
        toast.success("Password information updated successfully!");
        setName("");
        setEmail("");
        setEmailInvoice("");
      } else {
        setBtnLoading(false);
        toast.error(data?.error);
      }
    }
  };

  return (
    <div className="container">
      <div className="wrapper-full">
        <div className="page-content">
          <SideBar />
          <div className="right_side_bar">
            <Header title={"Change Password"} />

            <div className="content_inner">
              <div className="content_settings">
                {/* {getLoggedObject()?.ac_type != 9 ? ( */}
                <div
                  className="right_settings_box"
                  style={{ width: "70%", margin: "auto" }}
                >
                  <div className="form_setcion">
                    <div className="double_in_row">
                      <div className="form-group ">
                        <label>Old Password</label>
                        <input
                          type="password"
                          name="company_name"
                          className="setting_input"
                          placeholder=""
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div className="form-group ">
                        <label>New Password</label>
                        <input
                          type="password"
                          name="email_company"
                          className="setting_input"
                          placeholder=""
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>

                      <div className="form-group ">
                        <label>Confirm Password</label>
                        <input
                          type="password"
                          name="email_company"
                          className="setting_input"
                          placeholder=""
                          value={emailinvoice}
                          onChange={(e) => setEmailInvoice(e.target.value)}
                        />
                      </div>
                    </div>{" "}
                  </div>
                  <div className="">
                    <button
                      type="button"
                      className="button_submit"
                      disabled={btnloading}
                      onClick={() => do_update_company()}
                    >
                      Update
                    </button>
                  </div>
                </div>
                {/* ) : (
                  <>
                    <div className="right_settings_box">
                      <div className="only_admin">
                        Only admin can view settings, while you can perform
                        other actions...
                      </div>
                    </div>
                  </>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
