import { urls } from "./Api_urls";
export async function doPost(body_data,url_plus) {
    // console.log(" I request @ " + urls.API + url_plus);
    // console.log(body_data);
    const {isError, data} = await fetch(urls.API + url_plus, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body_data),
    }).then((response) => response.json())
      .then((responseJson) => {
        return {isError:false,data:responseJson}
      }).catch((error) => {
        // console.log(error);
        return {isError:true,data:error}
      });
    return {isError,data};
}

export async function doPostImage(body_data,url_plus) {
  const {isError, data} = await fetch(urls.API + url_plus, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body_data),
  }).then((response) => response.json())
    .then((responseJson) => {
      return {isError:false,data:responseJson}
    }).catch((error) => {
      // console.log(error);
      return {isError:true,data:error}
    });
  return {isError,data};
}


export async function check_role_restrictions() {
  try {
    const prev = localStorage.getItem("PROCXAUTH");
    let body_data = JSON.parse(prev);
    const response = await fetch(urls.API + 'auth/check_role_restrictions', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body_data),
    });
    const responseJson = await response.json();
    // console.log("RES", responseJson);
    return responseJson?.role;
  } catch (error) {
    console.error("Error:", error);
    throw error; // Rethrow the error to be handled by the caller
  }
}