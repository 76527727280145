import React, { useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { AiFillCloseCircle } from "react-icons/ai";
import Loader from "../../base-components/loader/index.jsx";

function Login({ title, ondeleteCall, onclosecall, loading = false }) {
  const navigate = useNavigate();

  const path = window.location.pathname;
  const lastSlashIndex = path.lastIndexOf("/");
  const slugFromUrl =
    lastSlashIndex !== -1 ? path.substring(lastSlashIndex + 1) : "";

  const handle_delete = () => {
    ondeleteCall();
  };

  return (
    <div className="container">
      <div className="wrapper-full">
        <div className="page-content">
          <div className="right_side_bar">
            <div className="content_inner">
              <div className="content_settings">
                <div className="popup_outer">
                  <div className="popup_inner">
                    <div
                      className="popup_white_center"
                      style={{ width: "auto" }}
                    >
                      <div className="modal_heading">
                        <span>Delete {title}</span>
                        <span
                          className="close_icon"
                          onClick={() => onclosecall()}
                        >
                          <AiFillCloseCircle />
                        </span>
                      </div>
                      <div
                        className=""
                        style={{
                          fontSize: "13px",
                          marginBottom: "20px",
                          lineHeight: "20px",
                        }}
                      >
                        Are you sure you want to delete this {title}?
                        <br />
                        This action won't be undone.
                      </div>
                      <div className="wd100">
                        <button
                          disabled={loading}
                          type="button"
                          className="button_submit wd100"
                          onClick={() => handle_delete()}
                        >
                          {loading ? <Loader /> : "Confirm"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
