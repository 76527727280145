import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { AiFillCloseCircle } from "react-icons/ai";
import Loader from "../../base-components/loader";

function ResetPopup({
  title,
  loading = false,
  open = false,
  onOpen = () => {},
  fun = () => {},
}) {
  const navigate = useNavigate();

  const handleOpen = () => {
    onOpen(!open);
  };

  return (
    <div className="container">
      <div className="wrapper-full">
        <div className="page-content">
          <div className="right_side_bar">
            <div className="content_inner">
              <div className="content_settings">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="popup_outer"
                >
                  <div className="popup_inner ResetPOP">
                    <div
                      className="popup_white_center"
                      style={{ width: "auto" }}
                    >
                      <div className="modal_heading">
                        <span>Restore Factory Settings</span>
                        <span className="close_icon" onClick={handleOpen}>
                          <AiFillCloseCircle />
                        </span>
                      </div>
                      <div
                        className=""
                        style={{
                          fontSize: "13px",
                          marginBottom: "20px",
                          lineHeight: "20px",
                        }}
                      >
                        {title}?
                        <br />
                      </div>
                      <div className="wd100">
                        <button
                          type="button"
                          disabled={loading}
                          className="button_submit wd100"
                          onClick={fun}
                        >
                          {loading ? <Loader /> : "Confirm"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPopup;
