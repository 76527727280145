import React from "react";
import "./style.css";

const ImagesPop = ({ imgsrc = "", onClose }) => {
  const handleContainerClick = (e) => {
    if (e.target.classList.contains("images_popupMain")) {
      onClose();
    }
  };

  const handleImageClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="images_popupMain" onClick={handleContainerClick}>
      <div className="ImageContainer">
        <img src={imgsrc} alt="popimg.png" onClick={handleImageClick} />
      </div>
    </div>
  );
};

export default ImagesPop;
