import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useAuth from "./../../hooks/useAuth";
import { LiaFileExportSolid } from "react-icons/lia";

function SideBar() {
  const { getLoggedObject, setLoggedObject } = useAuth();
  const navigate = useNavigate();
  const [darkmode, setDarkMode] = useState(2);

  const path = window.location.pathname;
  const lastSlashIndex = path.lastIndexOf("/");
  const slugFromUrl =
    lastSlashIndex !== -1 ? path.substring(lastSlashIndex + 1) : "";

  useEffect(() => {
    if (
      getLoggedObject()?.ac_type != -1 &&
      getLoggedObject()?.ac_type != 2 &&
      slugFromUrl != "notifications" &&
      slugFromUrl != "password"
    ) {
      navigate("/");
      return;
    }
    const DarkValue = localStorage.getItem("DarkMode");
    if (DarkValue === null || parseInt(DarkValue) === 0) {
      setDarkMode(2);
    } else {
      setDarkMode(1);
    }
  }, []);

  const do_make_action = () => {
    const collapseValue = localStorage.getItem("Collapse");
    if (collapseValue === null || parseInt(collapseValue) === 0) {
      localStorage.setItem("Collapse", "1");
      document.body.classList.add("nocollapse");
    } else {
      localStorage.removeItem("Collapse");
      document.body.classList.remove("nocollapse");
    }
  };

  const do_make = () => {
    document.body.classList.remove("mobile_view_p");
  };

  const set_dark_mode = (val) => {
    setDarkMode(val);
    if (val === 2) {
      localStorage.setItem("DarkMode", "1");
      document.body.classList.add("darkmind");
    } else {
      localStorage.removeItem("DarkMode");
      document.body.classList.remove("darkmind");
    }
  };

  const do_logout = async () => {
    setLoggedObject(null);
    localStorage.removeItem("PROCXAUTH");
    toast.success("You're logout successfully!");
    // navigate('/login', {replace: true});
    window.location.href = `${window.location.origin}/login`;
  };

  return (
    <>
      {(getLoggedObject()?.ac_type == -1 ||
        getLoggedObject()?.ac_type == 2) && (
        <div className="left_setting_bar">
          <Link
            to="/brands"
            className={`setting_bar ${
              slugFromUrl == "brands" ? "setting_active" : ""
            }`}
          >
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
              >
                <path
                  d="M7 11H15M11 15V7M8 21H14C19 21 21 19 21 14V8C21 3 19 1 14 1H8C3 1 1 3 1 8V14C1 19 3 21 8 21Z"
                  stroke={slugFromUrl == "brands" ? "#7FCBC4" : "#AFAAAA"}
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <div>Add Brands</div>
          </Link>
          <Link
            to="/members"
            className={`setting_bar ${
              slugFromUrl == "members" ? "setting_active" : ""
            }`}
          >
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
              >
                <path
                  d="M7 11H15M11 15V7M8 21H14C19 21 21 19 21 14V8C21 3 19 1 14 1H8C3 1 1 3 1 8V14C1 19 3 21 8 21Z"
                  stroke={slugFromUrl == "members" ? "#7FCBC4" : "#AFAAAA"}
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <div>Add Members</div>
          </Link>
          <Link
            to="/projects"
            className={`setting_bar ${
              slugFromUrl == "projects" ? "setting_active" : ""
            }`}
          >
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
              >
                <path
                  d="M7 11H15M11 15V7M8 21H14C19 21 21 19 21 14V8C21 3 19 1 14 1H8C3 1 1 3 1 8V14C1 19 3 21 8 21Z"
                  stroke={slugFromUrl == "projects" ? "#7FCBC4" : "#AFAAAA"}
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <div>Add Project</div>
          </Link>
          <Link
            to="/Request-Type"
            className={`setting_bar ${
              slugFromUrl == "Request-Type" ? "setting_active" : ""
            }`}
          >
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
              >
                <path
                  d="M7 11H15M11 15V7M8 21H14C19 21 21 19 21 14V8C21 3 19 1 14 1H8C3 1 1 3 1 8V14C1 19 3 21 8 21Z"
                  stroke={slugFromUrl == "Request-Type" ? "#7FCBC4" : "#AFAAAA"}
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <div>Add Request Type</div>
          </Link>
          <Link
            to="/categories"
            className={`setting_bar ${
              slugFromUrl == "categories" ? "setting_active" : ""
            }`}
          >
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
              >
                <path
                  d="M7 11H15M11 15V7M8 21H14C19 21 21 19 21 14V8C21 3 19 1 14 1H8C3 1 1 3 1 8V14C1 19 3 21 8 21Z"
                  stroke={slugFromUrl == "categories" ? "#7FCBC4" : "#AFAAAA"}
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <div>Add Categories</div>
          </Link>
          <Link
            to="/add-products"
            className={`setting_bar ${
              slugFromUrl == "add-products" ? "setting_active" : ""
            }`}
          >
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
              >
                <path
                  d="M7 11H15M11 15V7M8 21H14C19 21 21 19 21 14V8C21 3 19 1 14 1H8C3 1 1 3 1 8V14C1 19 3 21 8 21Z"
                  stroke={slugFromUrl == "add-products" ? "#7FCBC4" : "#AFAAAA"}
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <div>Add Products</div>
          </Link>
          <Link
            to="/add-suppliers"
            className={`setting_bar ${
              slugFromUrl == "add-suppliers" ? "setting_active" : ""
            }`}
          >
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
              >
                <path
                  d="M7 11H15M11 15V7M8 21H14C19 21 21 19 21 14V8C21 3 19 1 14 1H8C3 1 1 3 1 8V14C1 19 3 21 8 21Z"
                  stroke={
                    slugFromUrl == "add-suppliers" ? "#7FCBC4" : "#AFAAAA"
                  }
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <div>Add Vendors</div>
          </Link>
          <Link
            to="/add-customers"
            className={`setting_bar ${
              slugFromUrl == "add-customers" ? "setting_active" : ""
            }`}
          >
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
              >
                <path
                  d="M7 11H15M11 15V7M8 21H14C19 21 21 19 21 14V8C21 3 19 1 14 1H8C3 1 1 3 1 8V14C1 19 3 21 8 21Z"
                  stroke={
                    slugFromUrl == "add-customers" ? "#7FCBC4" : "#AFAAAA"
                  }
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <div>Add Customers</div>
          </Link>
          <Link
            to="/Export-reports"
            className={`setting_bar ${
              slugFromUrl == "Export-reports" ? "setting_active" : ""
            }`}
          >
            <span>
              <LiaFileExportSolid
                size={25}
                color={slugFromUrl == "Export-reports" ? "#7FCBC4" : "#AFAAAA"}
              />
            </span>
            <div>Export Reports</div>
          </Link>
          {(getLoggedObject()?.ac_type == -1 ||
            getLoggedObject()?.ac_type == 2) && (
            <Link
              to="/settings"
              className={`setting_bar ${
                slugFromUrl == "settings" || slugFromUrl == "notifications"
                  ? "setting_active"
                  : ""
              }`}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="21"
                  viewBox="0 0 22 21"
                  fill="none"
                >
                  <path
                    d="M11 13.439C12.6569 13.439 14 12.0958 14 10.439C14 8.78211 12.6569 7.43896 11 7.43896C9.34315 7.43896 8 8.78211 8 10.439C8 12.0958 9.34315 13.439 11 13.439Z"
                    stroke={slugFromUrl == "settings" ? "#7FCBC4" : "#AFAAAA"}
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M1 11.3189V9.5589C1 8.5189 1.85 7.6589 2.9 7.6589C4.71 7.6589 5.45 6.3789 4.54 4.8089C4.02 3.9089 4.33 2.7389 5.24 2.2189L6.97 1.2289C7.76 0.758901 8.78 1.0389 9.25 1.8289L9.36 2.0189C10.26 3.5889 11.74 3.5889 12.65 2.0189L12.76 1.8289C13.23 1.0389 14.25 0.758901 15.04 1.2289L16.77 2.2189C17.68 2.7389 17.99 3.9089 17.47 4.8089C16.56 6.3789 17.3 7.6589 19.11 7.6589C20.15 7.6589 21.01 8.5089 21.01 9.5589V11.3189C21.01 12.3589 20.16 13.2189 19.11 13.2189C17.3 13.2189 16.56 14.4989 17.47 16.0689C17.99 16.9789 17.68 18.1389 16.77 18.6589L15.04 19.6489C14.25 20.1189 13.23 19.8389 12.76 19.0489L12.65 18.8589C11.75 17.2889 10.27 17.2889 9.36 18.8589L9.25 19.0489C8.78 19.8389 7.76 20.1189 6.97 19.6489L5.24 18.6589C4.33 18.1389 4.02 16.9689 4.54 16.0689C5.45 14.4989 4.71 13.2189 2.9 13.2189C1.85 13.2189 1 12.3589 1 11.3189Z"
                    stroke={slugFromUrl == "settings" ? "#7FCBC4" : "#AFAAAA"}
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <div>Settings</div>
            </Link>
          )}
        </div>
      )}
    </>
  );
}
export default SideBar;
